<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        Assume that you have a salt composed of simple ions with the molecular formula
        <chemical-latex content="AB2(s)," />
        a molecular weight of
        <number-value :value="molWeight" unit="\text{g/mol,}" />
        and a solubility of
        <number-value :value="gramSol" unit="\text{g/L.}" />
      </p>

      <p class="mb-2">
        a) Determine the molar solubility,
        <stemble-latex content="$\text{S},$" />
        for this salt in
        <stemble-latex content="$\text{mol/L (M).}$" />
      </p>

      <calculation-input
        v-model="inputs.molarSolubility"
        class="mb-5"
        prepend-text="$\text{S:}$"
        append-text="$\text{M}$"
        :disabled="!allowEditing"
      />

      <p class="mb-0">
        b) Which of the following represents the expression for the
        <stemble-latex content="$\text{K}_\text{sp}$" />
        of the salt, in terms of the molar solubility,
        <stemble-latex content="$\text{S?}$" />
      </p>

      <v-radio-group
        v-model="inputs.KspExpression"
        class="mt-1 mb-0 pl-8"
        dense
        :disabled="!allowEditing"
      >
        <div v-for="option in options" :key="option.value">
          <v-radio class="d-inline-block" :value="option.value" />
          <stemble-latex :content="option.condition" />
        </div>
      </v-radio-group>

      <p class="mb-2">
        c) Calculate the
        <stemble-latex content="$\text{K}_\text{sp}$" />
        of the salt given the molar solubility,
        <stemble-latex content="$\text{S.}$" />
      </p>

      <calculation-input
        v-model="inputs.KspValue"
        prepend-text="$\text{K}_\text{sp}:$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question127',
  components: {
    StembleLatex,
    ChemicalLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        molarSolubility: null,
        KspExpression: null,
        KspValue: null,
      },
      options: [
        {condition: '$S^2$', value: 'S2'},
        {condition: '$2S^2$', value: '2S2'},
        {condition: '$2S^3$', value: '2S3'},
        {condition: '$4S^2$', value: '4S2'},
        {condition: '$4S^3$', value: '4S3'},
        {condition: '$27S^3$', value: '27S3'},
        {condition: '$27S^4$', value: '27S4'},
      ],
    };
  },
  computed: {
    molWeight() {
      return this.taskState.getValueBySymbol('molWeight').content;
    },
    gramSol() {
      return this.taskState.getValueBySymbol('gramSol').content;
    },
  },
};
</script>
